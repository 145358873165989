import React,{useState, useMemo, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../utils/helper";
import Select from "react-select";
import { generateSelectRoute,generateSelectData } from "../../../utils/serialiseResponse";
import {IoIosAddCircleOutline} from 'react-icons/io' ;
import customToast from "../../../components/CustomToast";
import { v4 as uuidv4 } from "uuid";
import {setTPForMonth} from "../../../reducers/tpReducer";
import { newTP , editTP, newTPAdmin} from "../../../services/tourProgram";
import {AiOutlineStop} from 'react-icons/ai' ;
import { RiEdit2Fill } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../reducers/locations/hqReducer";

const selectStyles = {
control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    borderRadius: "5px",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",
    width: "20rem",
    cursor: "pointer",
}),
option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
    backgroundColor: "#393b5f",
    },
}),
singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
}),
menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
}),
input: (provided, state) => ({
    ...provided,
    color: "#fff",
}),
dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
    color: "#9E9AD1",
    },
}),
};
const areaTypeOptions = [
    { value: 1, label: "Local" },
    { value: 2, label: "Ex-Station" },
    { value: 3, label: "Out-Station" },
];
const iconStyle = {
  fontSize:'30px', 
  cursor:"pointer"
}
const TP = ({ tp, month, year, user, isSundayWorking })=>{
//  console.log(isSundayWorking)
  const dispatch = useDispatch();
  const routes = useSelector(({ route }) => route);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const loggedIn = useSelector(({ user }) => user);
  const [areaType, setAreaType] = useState("");
  const [workAreas, setWorkAreas] = useState([]);
  const [editing , setEditing] = useState(false);

  const routeSelect = useMemo(() => generateSelectRoute(routes.data), [routes]);
  let filteredRoutes = routeSelect.filter((el) => el?.type === areaType?.label);
  let newRoutes = filteredRoutes.filter((el) => {
    if (el?.headquarter === loggedIn?.user?.headquarter) return el;
  });

  // useEffect(() => {
  //   dispatch(setHeadquarters());
  //   return () => {dispatch(clearHeadquarters());}
  // }, [dispatch]);

  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  useEffect(()=>{
    return ()=>{
      setEditing(false);
    }
  },[tp]);

  const handleTpAdd = async() => {
    if (!areaType || workAreas.length === 0 ){
      return customToast.error("Please fill all the required details");
    }
    let finalWorkAreas = [];
    workAreas.forEach((el) => {
      let count = 1;
      finalWorkAreas.push({ id: count, location: el });
      count++;
    });
    const plan = [
      {
        month: Number(month?.value),
        year: Number(year?.label),
        cdt:new Date(tp?.dateString).getTime() || new Date().getTime(),
        date: new Date(tp?.dateString),
        dateString: tp?.dateString,
        isMonthlyTP: false ,
        areaType: areaType?.label,
        workAreas: finalWorkAreas,
        ID: uuidv4(),
      },
    ];
    try {
      if(loggedIn?.user?.isAdmin)await newTPAdmin({body:plan, empId: user?.value})
      else await newTP(plan);
     
      dispatch(setTPForMonth({month:month?.value , year:year?.label,  userId: user?.value }));
      if(loggedIn?.user?.isAdmin)customToast.success("TP Successfully created for holiday");
      else customToast.success("TP Successfully sent for approval");
    } catch (err) {
      console.error("Some error", err);
    }
    setWorkAreas([]);
    setAreaType(null);
  };
  const handleEditTP = async() => {
    if (!areaType || workAreas.length === 0 ){
      return customToast.error("Please fill all the required details");
    }
    let finalWorkAreas = [];
    workAreas.forEach((el) => {
      let count = 1;
      finalWorkAreas.push({ id: count, location: el });
      count++;
    });
    const plan = [
      {
        month: Number(month?.value),
        year: Number(year?.label),
        cdt:new Date(tp?.dateString).getTime() || new Date().getTime(),
        date: new Date(tp?.dateString),
        dateString: tp?.dateString,
        isMonthlyTP: false ,
        areaType: areaType?.label,
        workAreas: finalWorkAreas,
        ID: uuidv4(),
      },
    ];
    try {
      await editTP({finalArray:plan , id:tp?._id});
      dispatch(setTPForMonth({month:Number(month?.value) , year:Number(year?.label) }));
      customToast.success("TP Successfully sent for approval");
    } catch (err) {
      console.error("Some error", err);
    }
    setWorkAreas([]);
    setAreaType(null);
  };
  if (((new Date(tp?.dateString).toLocaleString("en-us", { weekday: "long" }) === "Sunday"  ) || tp?.holiday || tp?.leave ) 
  ){
  // const isSunday = new Date(tp?.dateString).toLocaleString("en-us", { weekday: "long" }) === "Sunday" ;
  // const isHolidayOrLeave = tp?.holiday || tp?.leave;
  // if ((isSunday && !isSundayWorking) || isHolidayOrLeave 
  // ){
    // sunday is actually a holiday so handling it in a different way
    // the same thing will be done for leave and holidays created
    
    const status =tp?.isApproved;
  let statusClass = "";
  let statusIcon = "";
  switch (status) {
  case -1:
      statusClass = "tp__activity-types-icon-10";
      statusIcon = (<RiEdit2Fill style={iconStyle}></RiEdit2Fill>) ;
      break;
  case 1:
      statusClass = "tp__activity-types-icon-9";
      statusIcon = (<RiEdit2Fill style={iconStyle}></RiEdit2Fill>) ;
      break;
  case 0:
      statusClass = "tp__activity-types-icon-5";
      statusIcon = (<AiOutlineStop style={iconStyle}></AiOutlineStop>) ;
      break;
  default:
    statusIcon = (<IoIosAddCircleOutline style={iconStyle}></IoIosAddCircleOutline>) ;
  }

    if(!loggedIn?.user?.isAdmin && isSundayWorking === "true"){
      return (
        <tr>
          <td>
              <span className={(tp?.leave || tp?.holiday?.holidayTitle) ? "opacity-25": statusClass }>
                {formatDate(tp?.dateString).split(" ")[0]}
              </span>
          </td>
          <td >
              <span className={(tp?.leave || tp?.holiday?.holidayTitle) ? "opacity-25": statusClass }>
                {formatDate(tp?.dateString)}
              </span>
          </td>
          <td>
            <span className={(tp?.leave || tp?.holiday?.holidayTitle) && "opacity-25" } >
              {tp?.leave ? "Leave" : (tp?.holiday?.holidayTitle ||
                new Date(tp?.dateString).toLocaleString("en-us", {weekday:"long"}))
              }
            </span>
          </td>
          <td>{  
              (tp?.workAreas && !editing) ? (
                <span className={tp?.leave || tp?.holiday?.holidayTitle && "tp__activity-types-icon-4"}>{tp?.areaType}</span>
              ):
              (  !tp?.leave ? (
                  <Select
                    options={areaTypeOptions}
                    styles={selectStyles}
                    className="d-flex justify-content-center"
                    placeholder="Select Area Type"
                    value={areaType}
                    onChange={(e) => {
                    setAreaType({ ...e });
                        if (areaType?.value !== e?.value) setWorkAreas([]);
                    }}
                  />
                ) : null
              )
            }
          </td>
          <td>
            {  
              (tp?.workAreas && !editing) ? (
                tp?.workAreas?.map((e, i) => (
                  <span key={i} className={tp?.leave || tp?.holiday?.holidayTitle && "tp__activity-types-icon-4"}> 
                    {e.location.label} <br />
                  </span>
                ))
              ):
              (  !tp?.leave ? (
                  <Select
                    isMulti
                    options={areaType?.value === 1 ? hqSelect : filteredRoutes}
                    styles={selectStyles}
                    placeholder="Select Location"
                    value={workAreas}
                    onChange={(e) => setWorkAreas([...e])}
                    className="multi-select-dropdown d-flex justify-content-center"
                  />
                ) : null
              )
            }
          </td>
          <td>
          {editing && (
          <span>
            <IoIosAddCircleOutline onClick={handleEditTP}
              className="icon-color-green" 
              style={iconStyle}
            ></IoIosAddCircleOutline>
            <MdCancel className="icon-color-error"
              onClick={(e)=>setEditing(false)}
              style={iconStyle}>
            </MdCancel>
          </span>
        )}
            {
            !tp?.leave ? ( (!editing && tp?.workAreas) ? (
              <span className={statusClass} onClick={(e)=>{
                if(status !== 0){
                  setEditing(true);
                }
              }}
              >
                {statusIcon}
              </span>
            ):(
              !editing && (<span
                onClick={handleTpAdd}
                className={statusClass}
              >{statusIcon}</span>)
            ))
            : (<span className="opacity-25">
              //     <AiOutlineStop style={{fontSize:'30px', cursor:"not-allowed"}}></AiOutlineStop>
              //   </span>)
            
              // !tp?.workAreas && !tp?.leave ? (
              //   <IoIosAddCircleOutline onClick={handleTpAdd} style={iconStyle}></IoIosAddCircleOutline>
              // ) : (
              //   <span className="opacity-25">
              //     <AiOutlineStop style={{fontSize:'30px', cursor:"not-allowed"}}></AiOutlineStop>
              //   </span>
              // )
            }
          </td>
        </tr>
      )
    }
    
    return (
      <tr>
        <td>
            <span className={(!loggedIn?.user?.isAdmin || tp?.leave || tp?.holiday?.holidayTitle) ? "opacity-25": statusClass } >
              {formatDate(tp?.dateString).split(" ")[0]}
            </span>
        </td>
        <td >
            <span className={(!loggedIn?.user?.isAdmin || tp?.leave || tp?.holiday?.holidayTitle) ? "opacity-25": statusClass }>
              {formatDate(tp?.dateString)}
            </span>
        </td>
        <td>
          <span className={(!loggedIn?.user?.isAdmin || tp?.leave || tp?.holiday?.holidayTitle) && "opacity-25"} >
            {tp?.leave ? "Leave" : (tp?.holiday?.holidayTitle ||
              new Date(tp?.dateString).toLocaleString("en-us", {weekday:"long"}))
            }
          </span>
        </td>
        <td>{  
            tp?.workAreas ? (
              <span className={!loggedIn?.user?.isAdmin ? ( tp?.leave || tp?.holiday?.holidayTitle ? "tp__activity-types-icon-4" : "opacity-25") : ( tp?.leave || tp?.holiday?.holidayTitle && "tp__activity-types-icon-4")}>{tp?.areaType}</span>
            ):
            ( loggedIn?.user?.isAdmin && !tp?.leave ? (
                <Select
                  options={areaTypeOptions}
                  styles={selectStyles}
                  className="d-flex justify-content-center"
                  placeholder="Select Area Type"
                  value={areaType}
                  onChange={(e) => {
                  setAreaType({ ...e });
                      if (areaType?.value !== e?.value) setWorkAreas([]);
                  }}
                />
              ) : null
            )
          }
        </td>
        <td>
          {  
            tp?.workAreas ? (
              tp?.workAreas?.map((e, i) => (
                <span key={i} className={!loggedIn?.user?.isAdmin ? ( tp?.leave || tp?.holiday?.holidayTitle ? "tp__activity-types-icon-4" : "opacity-25") : ( tp?.leave || tp?.holiday?.holidayTitle && "tp__activity-types-icon-4")}> 
                  {e.location.label} <br />
                </span>
              ))
            ):
            ( loggedIn?.user?.isAdmin && !tp?.leave ? (
                <Select
                  isMulti
                  options={areaType?.value === 1 ? hqSelect : filteredRoutes}
                  styles={selectStyles}
                  placeholder="Select Location"
                  value={workAreas}
                  onChange={(e) => setWorkAreas([...e])}
                  className="multi-select-dropdown d-flex justify-content-center"
                />
              ) : null
            )
          }
        </td>
        <td>
          {  
            loggedIn?.user?.isAdmin && !tp?.workAreas && !tp?.leave ? (
              <IoIosAddCircleOutline onClick={handleTpAdd} style={iconStyle}></IoIosAddCircleOutline>
            ) : (
              <span className="opacity-25">
                <AiOutlineStop style={{fontSize:'30px', cursor:"not-allowed"}}></AiOutlineStop>
              </span>
            )
          }
        </td>
      </tr>
    )
  }
  const status =tp?.isApproved;
  let statusClass = "";
  let statusIcon = "";
  switch (status) {
  case -1:
      statusClass = "tp__activity-types-icon-10";
      statusIcon = (<RiEdit2Fill style={iconStyle}></RiEdit2Fill>) ;
      break;
  case 1:
      statusClass = "tp__activity-types-icon-9";
      statusIcon = (<RiEdit2Fill style={iconStyle}></RiEdit2Fill>) ;
      break;
  case 0:
      statusClass = "tp__activity-types-icon-5";
      statusIcon = (<AiOutlineStop style={iconStyle}></AiOutlineStop>) ;
      break;
  default:
    statusIcon = (<IoIosAddCircleOutline style={iconStyle}></IoIosAddCircleOutline>) ;
  }
    
  return (
    <tr>
        <td>
            <span className={statusClass}>
                {formatDate(tp?.dateString).split(" ")[0]}
            </span>
        </td>
        <td >
            <span className={statusClass}>
                {formatDate(tp?.dateString)}
            </span>
        </td>
        <td>{new Date(tp?.dateString).toLocaleString("en-us", {weekday:"long"})}</td>
        <td>{
            (tp?.workAreas && !editing) || (loggedIn?.user?.isAdmin) ? (
              <span>{tp?.areaType}</span>
            ):
            (
              <Select
                options={areaTypeOptions}
                styles={selectStyles}
                className="d-flex justify-content-center"
                placeholder="Select Area Type"
                value={areaType}
                onChange={(e) => {
                setAreaType({ ...e });
                    if (areaType?.value !== e?.value) setWorkAreas([]);
                }}
              />
            )
        }</td>
        <td>{
            (tp?.workAreas && !editing) || (loggedIn?.user?.isAdmin) ? (
              tp?.workAreas?.map((e, i) => (
                <span key={i}>
                  {e.location.label} <br />
                </span>
              ))
            ):
            (
              <Select
                isMulti
                options={areaType?.value === 1 ? hqSelect : newRoutes}
                styles={selectStyles}
                placeholder="Select Location"
                value={workAreas}
                onChange={(e) => setWorkAreas([...e])}
                className="multi-select-dropdown d-flex justify-content-center"
            />
            )
        }</td>
        <td>
        {editing && (
          <span>
            <IoIosAddCircleOutline onClick={handleEditTP}
              className="icon-color-green" 
              style={iconStyle}
            ></IoIosAddCircleOutline>
            <MdCancel className="icon-color-error"
              onClick={(e)=>setEditing(false)}
              style={iconStyle}>
            </MdCancel>
          </span>
        )}
        {loggedIn?.user?.isAdmin ? (
          <span className="opacity-25">
            <AiOutlineStop style={{fontSize:'30px', cursor:"not-allowed"}}></AiOutlineStop>
          </span>
          ): (!editing && tp?.workAreas) ? (
            <span className={statusClass} onClick={(e)=>{
              if(status !== 0){
                setEditing(true);
              }
            }}
            >
              {statusIcon}
            </span>
          ):(
            !editing && (<span
              onClick={handleTpAdd}
              className={statusClass}
            >{statusIcon}</span>)
          )
        }
        </td>
    </tr>
  )
}

export default TP ;