import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/tour-program/`;
const baseURLV1 = `${apiURL}/client/v1/tour-program/`;
const tpApprovalURL = `${apiURL}/client/tour-program/view-for-approval`;
const approveURL = `${apiURL}/client/tour-program/approve`;
const rejectURL = `${apiURL}/client/tour-program/reject`;

export const viewTP = async (details) => {
  const { data } = await axios.get(baseURL, {
    params: details,
    headers: setAuth(),
  });
  
  return data;
};

export const viewTPAggr = async (details) => {
 
  const { data } = await axios.get(baseURL +  "frequency", {
    params: details,
    headers: setAuth(),
  });
  
  return data;
};
export const viewTPForMonth = async (details) => {
  const { data } = await axios.get(baseURLV1 + "month", {
    params: details,
    headers: setAuth(),
  });
  
  return data;
};

export const viewTPWithStatus = async (details) => {
  
  const { data } = await axios.get(baseURL + "view-tp-for-status", {
    params: details,
    headers: setAuth(),
  });
 
  return data;
};

export const viewTPForDCR = async () => {
  const { data } = await axios.get(baseURL + "view-tp-for-dcr", {
    headers: setAuth(),
  });

  return data;
};

export const viewTPForDate = async (details) => {
  const { data } = await axios.get(baseURLV1 + "view-tp-for-date", {
    params: details,
    headers: setAuth(),
  });

  return data;
};

export const viewTPForApproval = async (details) => {
  const { data } = await axios.get(tpApprovalURL, {
    params: details,
    headers: setAuth(),
  });
 
  return data;
};

export const newTP = async (tpData) => {
  const { data } = await axios.post(baseURLV1, tpData, {
    headers: setAuth(),
  });

  return data;
};
export const newTPAdmin = async(tpData) => {
  const {data} = await axios.post(baseURLV1 + "adminTP", tpData, {
  // const {data} = await axios.post(baseURL + "adminTP", tpData, {
    headers:setAuth(),
  })
  return data;
}
export const editTP = async (tpData) => {
  const { data } = await axios.put(baseURLV1 + "edit", tpData, {
    headers: setAuth(),
  });

  return data;
};

export const tpApproval = async (id, details) => {
  const { data } = await axios.post(`${approveURL}/${id}`, details, {
    headers: setAuth(),
  });

  return data;
};

export const tpRejection = async (id, details) => {
  const { data } = await axios.post(`${rejectURL}/${id}`, details, {
    headers: setAuth(),
  });

  return data;
};

export const tpDeletion = async (id,details) => {
  const {data} = await axios.post(`${baseURL}delete/${id}`, details, {
    headers:setAuth(),
  })

  return data;
}

export const viewUsersWithPendingTP = async() => {
  const { data } = await axios.get(`${baseURL}users-pending-TP`,{
    headers: setAuth(),
  });
  return data;
}