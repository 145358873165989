import React,{ useState, useMemo, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import customToast from "../../../../components/CustomToast";
import { MdCheckCircleOutline , MdDelete, MdCancel, MdArrowBackIosNew, MdDeleteOutline } from "react-icons/md";
import {AiFillSave} from 'react-icons/ai';
import Table from "../../../../components/Table";
import {FaCalendar, FaCartPlus,} from "react-icons/fa" ;
import {ImLocation} from 'react-icons/im';
import {MdDownload} from "react-icons/md" ;
import { formatDate , getDiscountedPrice, fileDownloader } from "../../../../utils/helper";
import { RiEdit2Fill, RiCloseCircleLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import serialise from "../../../../utils/serialiseResponse";
import { viewInventoryAlerts } from "../../../../reducers/products/MinimumStockReducer";
import { clearPrimarySalesError, deletePrimarySale, editPrimarySale, clearPrimarySale } from "../../../../reducers/Sales/primarySales";
const headerSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    border: "2px solid #36ABF9",
    borderRadius: "5px",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",

    width: "12rem",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
};

const EditPrimarySales = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {error} = useSelector(({primarySales}) => primarySales)
  const [showEdit , setShowEdit] = useState(false);
  const loggedIn = useSelector(({ user }) => user);
  const [finalArray, setFinalArray] = useState([]);//this is the array that will get submitted
  const [editRow , setEditRow] = useState("");
  const [editing , setEditing] = useState(false);
  const [files,setFiles] = useState("");
  //These are the variables that do not require re-rendering
  const upQuantityRef = useRef(0);
  const upCustomPriceRef = useRef(0);
  const upFreeStocksRef = useRef(0);
  const upDiscountRef = useRef(0);

  const resetForm = ()=>{
    setFinalArray([]);
  }

  useEffect(()=>{
    if(error){
      dispatch(clearPrimarySalesError());
      return customToast.error(error)
    }
  },[error, dispatch])
  
  useEffect(() => {
    
    // Add the totalQuantity field to each product
    const updatedProducts = location?.state?.products.map(product => ({
      ...product,
      totalQuantity: product.freeStocks + product.quantity
    }));

    // Set the updated products array to the state
    setFinalArray(serialise(updatedProducts));

    // setFinalArray(serialise(location?.state?.products))
    setFiles(location?.state?.files || "");
    return () => {
      resetForm()
    }
  }, [])

  
  const deleteHandler = (product)=>{
    setEditRow("");
    setEditing(false);
    let sr = 0 ;
    const newfinalArray = finalArray.filter((ele , idx)=>{
      if(ele?.product?._id !== product?._id){
        sr += 1 ;
        ele.sr = sr ;
        return true ;
      }else return false ;
    })
    setFinalArray(newfinalArray);
    
  }
  const handleEditRow = (original)=>{
    if(editing){
      customToast.error("Please save the changes you just made");
      return ;
    }
    setEditing(true);
    setEditRow(original?.sr);
    upQuantityRef.current = original?.quantity ;
    upFreeStocksRef.current = original?.freeStocks ;
    upDiscountRef.current = original?.discount ;
    upCustomPriceRef.current = original?.price ;
  }
  const submitEditedRow = (sr) => {
    const editedObj = finalArray[sr - 1];
    editedObj.quantity = Number(upQuantityRef.current);
    editedObj.freeStocks = Number(upFreeStocksRef.current);
    editedObj.discount = Number(upDiscountRef.current);
    editedObj.price = upCustomPriceRef.current;
    const total = Number(
      Number(editedObj.quantity) * Number(editedObj.price)
    );
    const dis = Number(editedObj.discount);
    editedObj.finalPrice = getDiscountedPrice(total, dis);
    setEditing(false);
    setEditRow("");
  };
  const cancelHandler = (sr) => {
    setEditing(false);
    setEditRow("");
  };
  
  const primarySalesSubmit = useCallback(
    (e) => {
      if(editing){
        customToast.error("Please save the changes you just made");
        return ;
      }
      e.preventDefault();
      // secondaryQty = primaryQty + openingBalance - (salesReturn + closingQty);
      const salesData = {
        id:(location?.state?._id),
        table:finalArray,
      };
      dispatch(editPrimarySale(salesData));
      dispatch(viewInventoryAlerts());
      dispatch(clearPrimarySale());
      //send data to backend here and put dependencies in below array
      resetForm();
    },
    [finalArray,editing]
  );
  
  const EditPopup = () => {
    const dispatch = useDispatch();

    const handleDelete = (e) => {
      e.preventDefault();
      dispatch(deletePrimarySale({id:location?.state?._id}));
      dispatch(clearPrimarySale());
      navigate(-1);
      setShowEdit(false);
      customToast.success("Deleted successfully")
      
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Sale</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Do you want to PERMANENTLY delete this Sale?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 50,
      //   disableFilters: true,
      //   maxWidth: 50,
      // },
      {
        Header: "Product",
        accessor: "product.name",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 160,
        maxWidth: 160,
        showTotal: true,
        placeholderTotal: "Total",
      },
      {
        Header: "Unit Price",
        accessor: "product.price",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "price",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return (editable )? (
            <input
              defaultValue={props?.row?.original?.price}
              onChange={(e) => {
                upCustomPriceRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input"
            />
          ) : (
            <span>{`₹ ${props?.row?.original?.price}`}</span>
          );
        },
      },
      {
        Header: "Price Type",
        accessor: "priceType",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 60,
        maxWidth: 60,
        showTotal: true,
        placeholderTotal: "Total",
        Cell:(props) =>{
          return <span>{(props?.row?.original?.priceType || "na").toUpperCase()}</span>
        }
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal: true,
        totalAccessor: "quantity",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.quantity}
              onChange={(e) => {
                upQuantityRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.quantity}</span>
          );
        },
      },
      {
        Header: "Free Stocks",
        accessor: "freeStocks",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "freeStocks",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.freeStocks}
              onChange={(e) => {
                upFreeStocksRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.freeStocks}</span>
          );
        },
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "totalQuantity",
        Cell: (props) =>
          Number(
            Number(props?.row?.original?.quantity) +
              Number(props?.row?.original?.freeStocks)
          ),
      },
      {
        Header: "Discount %",
        accessor: "discount",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.discount}
              onChange={(e) => {
                upDiscountRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.discount}</span>
          );
        },
      },
      {
        Header: "Final Price",
        accessor: "finalPrice",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "finalPrice",
        Cell:(props) => {
          return <div>
            {`₹ ${props?.row?.original?.finalPrice}`}
          </div>
        }
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        Cell: (props) => {
          if(loggedIn?.user?.isAdmin){
          return editing && Number(props?.row?.original?.sr) === editRow ? (
            <React.Fragment>
              <AiFillSave
                onClick={(e) => {
                  submitEditedRow(props?.row?.original?.sr);
                }}
                type="button"
                className="icon-color-green"
              />
              {/* <MdDelete
                onClick={() => {
                  deleteHandler(props?.row?.original?.product);
                }}
                type="button"
                className="button-delete__icon"
              /> */}
              <MdCancel
                type="button"
                className="icon-color-yellow"
                onClick={() => {
                  cancelHandler(props?.row?.original?.sr);
                }}
              />
            </React.Fragment>
          ) : (
            <span
              className="react-table-view-link"
              onClick={() => {
                handleEditRow(props?.row?.original);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        } else {
          return (
            <span>Not Allowed</span>
          )
        }
        },
      },
    ],
    [finalArray, editing]
  );
  
  return (
    <main className="main-content admin-content">
      <div className="expense">
        <h3
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center", justifyContent:"space-between" }}
          >
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Edit Primary Sale
            </span>
          </div>
          {loggedIn?.user?.isAdmin?<span
              className="react-table-view-link"
              onClick={() => {
                setShowEdit(true);
              }}
            >
              <MdDelete className="icon-color-error" />
          </span>:null}
        </h3>
          <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <FaCalendar className="fs-2 text-light"/>
              <h3 className="d-inline ms-3">{formatDate(location?.state?.date)}</h3>
            </div>
            <div className="util-inner-info-box">
              <ImLocation className="fs-2 text-light" />
              <h3 className="d-inline ms-3">
                  {location?.state?.headquarter}
              </h3>
            </div>
            <div className="util-inner-info-box">
              <FaCartPlus className="fs-2 text-light"/>
              <h3 className="d-inline ms-3">
                {location?.state?.stockist}
              </h3>
            </div>
            <div onClick={(e)=>fileDownloader(files)} className="util-inner-info-box cursor-pointer">
              <MdDownload className="fs-1 text-light"/>
              <h3 className="d-inline ms-3">Download</h3>
            </div>
          </div>
          <div className="filter-table" style={{minHeight:"150px" , overflow:"auto"}}>
            <Table columns={columns} data={finalArray} />
          </div>
          {finalArray?.length > 0 && (
            <button
                className="button-submit-green mt-4 mx-auto"
                onClick={primarySalesSubmit}
            >
                <span className="button-submit-green__icon-container">
                <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text ">
                  Submit Edited Data
                </span>
            </button>
          )}
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default EditPrimarySales;
