import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { adminStyles } from "../../../public/stylesheets/selectStyles";
import serialise, {
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import {
  addCity,
  clearCities,
  deleteCities,
  editCities,
  setCities,
} from "../../../../reducers/locations/cityReducer";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import { MdDelete, MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import { fetchDependents } from "../../../../services/locations";

const AdminCity = () => {
  const dispatch = useDispatch();
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);
  const [showDelete, setShowDelete] = useState(false);
  const [dependents, setDependents] = useState([])
  const [loading, setLoading] = useState(true)
  const [showConfirmDelete, setShowConfrimDelete] = useState(false)

  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  const typeSelect = [
    { value: 1, label: "City" , areaType: "city"},
    { value: 2, label: "Local Area" , areaType: "localArea"},
  ];

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState();
  const [city, setCity] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [cityData, setCityData] = useState();
  const [areaData, setAreaData] = useState([]);
  const [cityData2, setCityData2] = useState([]);
  const [type, setType] = useState(typeSelect[0]);

  const EditPopup = ({ data, type }) => {
    const dispatch = useDispatch();
    const [city, setCity] = useState("");

    let updatedData = { ...data, name: city };
    const handleEdit = async (e) => {
      e.preventDefault();
      try {
        await dispatch(editCities(updatedData?._id, updatedData, data?.type === "city" ? "city" : "localArea"));
        customToast.success(`${data.type === "city" ? "City" : "Area"} Successfully Updated`);
        setShowEdit(false);
        dispatch(setCities("", type.areaType));
      } catch (error) {
        customToast.error(error?.response?.data?.data || "Something went wrong !");
      }
    };

    // const handleDelete = async() => {
    //   await dispatch(deleteCities(updatedData?._id, type?.value === 1 ? "city" : "localArea"));
    //   setShowEdit(false);
    //   setShowDelete(false);
    //   dispatch(setCities("", type.areaType));
    // };

    const handleDelete = () => {
      setShowConfrimDelete(true)
    };

    const handleConfirmDelete = () => {
      dispatch(deleteCities(updatedData?._id, data?.type === "city" ? "city" : "localArea"));
      customToast.success(`${data.type === "city"? "City" : "Area"} Successfully Deleted`);
      setShowEdit(false);
      setShowDelete(false);
      setShowConfrimDelete(false)
      dispatch(setCities("", type.areaType));
    };

    useEffect(() => {
      setCity(data.name);
    }, [data]);

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">{type} Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => type === 'edit' ? setShowEdit(false) : setShowDelete(false)}
            />
          </div>

          {type === 'edit' ? (<form onSubmit={handleEdit}>
            <div className="edit-form">
              <div>
                <label htmlFor="city">
                  Edit City <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="city"
                  className="me-5"
                  placeholder="Enter city"
                  value={city}
                  onChange={({ target }) => setCity(target.value)}
                />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>
            </div>
          </form>) :
            (<div>
              <h1>{city}</h1>
              {/* {dependents.length > 0 ? dependents.map((item, index) => {
                dependentsCount += item.data.value.length
                return (
                  <div key={index} className="d-flex align-items-center">
                    <select id={item.name} className="w-50 my-2">
                      <option defaultValue={item.name} >{item.name}: {item.data.value.length}</option>
                      {item.data.value.map((itemm, indexx) => {
                        return (
                          <option disabled key={indexx} value={itemm.name}>{itemm.name}</option>
                        );
                      })}
                    </select>
                  </div>
                );
              }) : <h3>No Dependencies</h3>} */}
              {
                loading ? <div>Loading...</div> : 
                <>
                {
                dependents?.totalCount > 0 ?
                <div>
                  <h2 className="text-danger my-4">{dependents?.totalCount} Dependencies</h2>
                  <p>Please delete or edit the following entries in order to delete  this State.</p>
                  <div>Stockist ({dependents.stockistCount})</div>
                  <div>Chemist ({dependents.chemistCount})</div>
                  <div>Doctors ({dependents.doctorCount})</div>
                </div>
                : <h2 className="my-4" style={{color: "#2ecc71"}}>No Dependencies</h2>
              }
                </>
              }
              {showConfirmDelete ? <section className="edit-popup" style={{ position: "absolute", top: "50%"}}>
                <div className="edit-content" style={{width: "40%"}}>
                  <div className="edit-popup__heading" style={{display: "flex", justifyContent: "flex-end"}}>
                    {/* <h2 className="web-app__heading">Details</h2> */}
                    <RiCloseCircleLine
                      className="edit-popup__close"
                      onClick={() => {
                        setShowDelete(false)
                        setShowConfrimDelete(false)
                      }}
                    />
                  </div>
                  <div>Are you sure want to delete <span className="text-danger"><br />{dependents?.totalCount} dependencies </span>?</div>
                  
                <div className="edit-form-button-container">
                <button
                  type="submit"
                  className="button-delete mt-4"
                  onClick={handleConfirmDelete}
                >
                  <span className="button-delete__icon-container">
                    <MdDeleteOutline className="button-delete__icon" />
                  </span>
                  <span className="button-delete__text">Delete</span>
                </button>
                <button
                  className="button-submit-green mt-4"
                  onClick={() => {
                    setShowDelete(false)
                    setShowConfrimDelete(false)
                  }}
                >
                  <span className="button-submit-green__icon-container">
                  <RiCloseCircleLine className="button-submit-green__icon" style={{fontSize: "1.5em"}}/>
                  </span>
                  <span className="button-submit-green__text">Cancle</span>
                </button>
                </div>
                </div> 
                </section> : null}
            </div>
            )}
          {!loading && showDelete === true && (<button
            type="submit"
            className="button-delete mt-4"
            onClick={handleDelete}
          >
            <span className="button-delete__icon-container">
              <MdDeleteOutline className="button-delete__icon" />
            </span>
            <span className="button-delete__text">Delete</span>
          </button>)}
        </div>
      </section>
    );
  };

  const fetchDependencies = async (data) => {
    try {
      const response = await fetchDependents(data._id, 'city');
      setDependents(response?.data);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const setEditForm = (data) => {
    setShowEdit(false);
    setCity(data?.name);
    setCityData(data);
  };

  useEffect(() => {
    dispatch(setStates());
    dispatch(setCities("", type.areaType));
    return () => {
      dispatch(clearStates());
      dispatch(clearCities());
    };
  }, [dispatch, state, type]);

  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);
  
  useEffect(() => {
    setAreaData(cities?.data?.filter((e) => e.type === "localArea"));
    setCityData2(cities?.data?.filter((e) => e.type !== "localArea"));
  }, [type, cities])

  const cityColumns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "City",
        accessor: "name",
      },
      {
        Header: "UID",
        accessor: "uid",
      },
      {
        Header: "HQ",
        accessor: "headquarter",
      },
      {
        Header: "State",
        accessor: "state",
        disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span>
              <span
                className="react-table-view-link"
                onClick={() => {
                  setEditForm(props.row.original);
                  setShowEdit(true);
                  setLoading(true)
                }}
              >
                <RiEdit2Fill className="icon-color-green" />
              </span>
              <span
                className="react-table-view-link mx-3"
                onClick={() => {
                  setEditForm(props.row.original);
                  setShowDelete(true);
                  setLoading(true)
                  fetchDependencies(props.row.original)
                }}
              >
                <MdDelete type="button"
                  className="button-delete__icon" size={25} />
              </span>
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const areaColumns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Local Area",
        accessor: "name",
      },
      {
        Header: "UID",
        accessor: "uid",
      },
      {
        Header: "HQ",
        accessor: "headquarter",
      },
      {
        Header: "State",
        accessor: "state",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span>
              <span
                className="react-table-view-link"
                onClick={() => {
                  setEditForm(props.row.original);
                  setShowEdit(true);
                  setLoading(true)
                }}
              >
                <RiEdit2Fill className="icon-color-green" />
              </span>
              <span
                className="react-table-view-link mx-3"
                onClick={() => {
                  setEditForm(props.row.original);
                  setShowDelete(true);
                  setLoading(true)
                  fetchDependencies(props.row.original)
                }}
              >
                <MdDelete type="button"
                  className="button-delete__icon" size={25} />
              </span>
            </span>
            
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!state || !headquarter || !city)
      return customToast.error("Please fill all details");

    try {
      const payload = {
        name: city,
        stateId: state.value, 
        headquarterId: headquarter.value
      }
      if(type.value === 1) payload.type = "city"
      if(type.value === 2) payload.type = "localArea"

      await dispatch(addCity(payload));
      customToast.success(`${type.value === 1 ? "City" : "Area"} Successfully Created`);
      setState(null);
      setHeadquarter(null);
      setCity("");
      dispatch(setCities("", type.areaType));
      setAreaData(cities?.data?.filter((e) => e.type === "localArea"));
      setCityData2(cities?.data?.filter((e) => e.type !== "localArea"));
    } catch (e) {
      customToast.error("Either city/local area already exists or We Encountered an Error");
    }
  };
  const tableData = useMemo(() => {
   return type.value === 1 ? serialise(cityData2) : serialise(areaData)
  }, [type, areaData, cityData2]);

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Create City / Area" />

        <section className="area-creation-content__form">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 mb-5">
                <label htmlFor="type">
                  Type <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="type"
                  id="type"
                  value={type}
                  options={typeSelect}
                  onChange={(e) => setType({ value: e.value, label: e.label , areaType: e.areaType})}
                  styles={adminStyles}
                  placeholder="City"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">
                  Select State <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={(e) => setState({ value: e.value, label: e.label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">
                  Select HQ <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={hqSelect}
                  onChange={(e) =>
                    setHeadquarter({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="city">
                  Enter {type?.value === 1 ? "City" : "Local Area"} Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                  className="me-5"
                  id="city"
                  placeholder={`Enter ${type.value === 1 ? "City" : "Local Area"} Name`}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 mt-5 admin-content-submit-button-left">
              <button type="submit" className="button-blue-gradient">
                {type.value === 1 ? "Add City" : "Add Area" }
              </button>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
        {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) {type.value === 1 ? "Cities" : "Local Areas"}
            </h2>
          )}
          <div className="filter-table">
            <Table columns={type.value === 1 ? cityColumns : areaColumns} data={tableData} />
          </div>
          {showEdit ? <EditPopup data={cityData} type={'edit'} /> : null}
          {showDelete ? <EditPopup data={cityData} type={'delete'} /> : null}
        </section>
      </div>
    </div>
  );
};

export default AdminCity;
