import { useEffect, useState, useMemo, useCallback } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import customToast from "../../../../components/CustomToast";
import {
  setTPForMonth,
  clearTourProgram,
} from "../../../../reducers/tpReducer";
import {
  clearHeadquarters,
  setHeadquartersForReporting,
} from "../../../../reducers/locations/hqReducer";
import {
  clearRoutes,
  setRoutesForReportingOnly,
} from "../../../../reducers/locations/routeReducer";
import { generateSelectUser } from "../../../../utils/serialiseResponse";
import { selectUserStyles, dcrStyles } from "../../../../views/public/stylesheets/selectStyles.js";
import {
  monthOptions,
  yearOptions
} from "../../../../utils/helper";
import { MdOutlineGroup } from "react-icons/md";
import { viewUsersForUtilities,viewMedRepresentatives } from "../../../../services/utilities";
import TP from "../SingleDayTP";


const TourForm = () => {
  const dispatch = useDispatch();
  const tpData = useSelector(({ tp }) => tp);
  const loggedIn = useSelector(({ user }) => user);
  const tpDeadline = useSelector((state) => state.settings["tp-deadline"]);
  const tourlimit = useSelector((state) => state.settings["tourlimit"]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState(yearOptions[2]);
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const sundayWorking = useSelector((state) => state.settings["sundayWorking"]);
  
  useEffect(() => {
    dispatch(setHeadquartersForReporting({ withWorkAreas: true }));
    

    return () => {
      dispatch(clearHeadquarters());
      dispatch(clearRoutes());
      dispatch(clearTourProgram());
    };
  }, [dispatch]);
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees([...res.data]);
      });
      // viewMedRepresentatives({includeMe:true}).then((res) => {
      //   setEmployees([...res.data]);
      // });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    let date = new Date().toISOString();
    if(date > tpDeadline) return customToast.error("TP cannot be submitted - deadline has passed");
    let error = false;
    try {
      dispatch(setRoutesForReportingOnly({month:month?.value , year:year?.label, adminTourLimit: tourlimit }));
      await dispatch(setTPForMonth({ month: month?.value, year: year?.label }));
      
    } catch (err) {
      customToast.error("Error occured please try again");
      error = true;
    }
    if (!error) {
      customToast.success("Request submitted Successfully");
    }
  };

  
  useEffect(() => {
    if(!month || !year || !selectedUser)return ;
    dispatch(setTPForMonth({month:month?.value , year:year?.label, userId: selectedUser?.value }));
    dispatch(setRoutesForReportingOnly({month:month?.value , year:year?.label, userId: selectedUser?.value, adminTourLimit: tourlimit }));
  }, [selectedUser, month, year, dispatch, tourlimit]);
  return (
    <form className="tp__content" onSubmit={handleSubmit}>
      <div className="row row-bg2">
        <div className="col-md-4 col-lg-3 util-tp-filter">
          <p className="mb-2">
            Select Month <span className="asterisk-imp">*</span>
          </p>
          <Select
            options={monthOptions}
            styles={dcrStyles}
            value={month}
            onChange={(e) => {
              setMonth({ ...e });
            }}
          />
        </div>
        <div className="col-md-4 col-lg-3 util-tp-filter">
          <p className="mb-2">
            Select Year <span className="asterisk-imp">*</span>
          </p>
          <Select
            styles={dcrStyles}
            options={yearOptions}
            placeholder="Select Year"
            value={year}
            onChange={(e) => setYear({ ...e })}
          />
        </div>
        <div className="col-md-4 col-lg-3 util-tp-filter">
          {loggedIn?.user?.isAdmin ? (
              <div>
                <p className="util-select-user__label">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser({ ...e })}
                  />
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="mt-5 ms-auto button-blue-gradient"
                onClick={(e) => {
                  if (!month) return customToast.error("Please select a month");
                  else if (!year)
                    return customToast.error("Please select a year");
                  handleSubmit(e);
                }}
              >
                Submit
              </button>)
            }
        </div>
      </div>
      <div className="tp-tags d-flex mt-4 gap-md-3 gap-lg-5">
        <h4>
          <span className="tp-indicator-9 me-2"></span>Approved
        </h4>
        <h4>
          <span className="tp-indicator-10 me-2"></span>Rejected
        </h4>
        <h4>
          <span className="tp-indicator-5 me-2"></span>Pending
        </h4>
        <h4>
          <span className="tp-indicator-3 me-2"></span>Leave
        </h4>
      </div>
      <div className="filter-table">
        <table className="primarysales-table">
          <tbody id="primarysales-data">
            <tr className="primarysales-table__header">
              <th>Sr no.</th>
              <th>Date</th>
              <th>Day</th>
              <th>Area Type</th>
              <th>Location</th>
              <th>Actions</th>
            </tr>
            {
              tpData?.data?.map((tp,idx)=>{
                return (
                  <TP key={idx} tp={tp} month={month} year={year} user={selectedUser} isSundayWorking={sundayWorking}></TP>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default TourForm;
