import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/location/`;
const baseURLV1 = `${apiURL}/client/v1/location/`;
const approvalURL = `${apiURL}/client/location/approval/`;
const acceptURL = `${apiURL}/client/location/approval/accept`;
const rejectURL = `${apiURL}/client/location/approval/reject`;
const addAreaForApprovalURL = `${apiURL}/client/location/city`;
const getAllLocationURL = `${apiURL}/client/location/`;
const getAllLocationURLV1 = `${apiURL}/client/v1/location/`;
const getDependenst = `${apiURL}/client/v1/location/dependents`
const locationUrl2 = `${apiURL}/client/v1/location/`

export const viewLocations = async (type, parentId, areaType) => {
  const { data } = await axios.get(baseURL + type, {
    params: { parentId , areaType},
    headers: setAuth(),
  });

  return data;
};

export const viewLocationsV1 = async (type, parentId, areaType) => {
  const {data} = await axios.get(locationUrl2 + type, {
    params: { parentId, areaType },
    headers: setAuth(),
  })

  return data;
}

export const viewDeltedLocations = async (type) => {
  const { data } = await axios.get(`${baseURL}${type}/view-deleted-${type}`, {
    // params: {filter},
    headers: setAuth(),
  });
  return data;
};

export const restoreDeltedLocations = async (type, id) => {
  const { data } = await axios.put(`${baseURL}${type}/update-deleted-${type}/${id}`, { id: id }, {
    headers: setAuth(),
  });
  return data;
}

export const viewLocationsForReporting = async (type, filter) => {
  const { data } = await axios.get(baseURLV1 + type, {
    params: { filter },
    headers: setAuth(),
  });

  return data;
};
// there is mistake in above function
export const viewLocationsForReporting2 = async (type, filter) => {
  const { data } = await axios.get(baseURLV1 + type, {
    params: filter ,
    headers: setAuth(),
  });

  return data;
};

export const viewAllLocation = async (type, filter) => {
  const { data } = await axios.get(getAllLocationURLV1 + type, {
    params: filter,
    headers: setAuth(),
  });

  return data;
};

export const viewLocationsForApproval = async (type) => {
  const { data } = await axios.get(approvalURL + type, {
    headers: setAuth(),
  });

  return data;
};

export const newLocation = async (type, details) => {
  const { data } = await axios.post(baseURL + type, details, {
    headers: setAuth(),
  });

  return data;
};

export const newLocationForApproval = async (details) => {
  const { data } = await axios.post(locationUrl2 + "city", details, {
    headers: setAuth(),
  });

  return data;
};

export const editLocation = async (type, id, updatedLoc) => {
  const { data } = await axios.put(baseURL + `${type}/${id}`, updatedLoc, {
    headers: setAuth(),
  });

  return data;
};

export const deleteLocation = async (type, id) => {
  await axios.post(
    baseURL + `${type}/${id}`,
    { params: { id } },
    {
      headers: setAuth(),
    }
  );
};

export const approveLocation = async (type, id, details) => {
  const { data } = await axios.post(`${acceptURL}/${type}/${id}`, details, {
    headers: setAuth(),
  });

  return data;
};

export const rejectLocation = async (type, id, details) => {
  const { data } = await axios.post(`${rejectURL}/${type}/${id}`, details, {
    headers: setAuth(),
  });

  return data;
};

export const fetchDependents = async (id, type) => {
  const { data } = await axios.get(`${getDependenst}/${type}/${id}`, {
    headers: setAuth(),
  })
  return data;
}

export const fetchLocationForId = async (id, type) => {
  const {data} = await axios.get(baseURL + `${type}/${id}`, {
    headers: setAuth()
  });
  return data;
};

export const fetchLocationV1 = async(type, parentId, areaType, isStatus) => {
  const {data} = await axios.get(locationUrl2 + `${type}`,{
    params:{parentId, areaType, isStatus},
    headers: setAuth()
  });
  return data;
};

export const editLocationV1 = async(type, id, updatedLoc) => {
  const {data} = await axios.put(locationUrl2 + `${type}/${id}`,updatedLoc,{
    headers:setAuth()
  });
  return data;
};

export const addLocationV1 = async(type, details) => {
  const {data} = await axios.post(locationUrl2 + `${type}`, details,{
    headers: setAuth()
  });
  return data;
};

export const deleteLocationV1 = async (type, id) => {
  await axios.post(
    locationUrl2 + `${type}/${id}`,
    { params: { id } },
    {
      headers: setAuth(),
    }
  );
};